<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('types', 'create')" type="is-primary create" rounded @click="modalCreate($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field :label="$t('labels.order')" label-position="on-border">
					<b-select v-model="order" :placeholder="$tc('fields.name')" @input="filterByOrder">
						<option selected value="name">{{ $tc('fields.name') }}</option>
						<option value="created_at">{{ $t('fields.date') }}</option>
					</b-select>
				</b-field>
				<b-field>
					<b-input :placeholder="$t('buttons.search')" type="search" icon-right="magnify" @input="findByName" v-model="name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :back="true" />
		<Results v-if="types.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="r in placeholder" :key="r" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="t in types" :key="t.id" class="list-item column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" :style="{ 'border-left-color': t.color }" @click.self="modalEdit(t.id)">
					<div class="block__content" @click="modalEdit(t.id)">
						<h3 class="block__name" translate="no">{{ t.name }}</h3>
						<p class="block__email">{{ format(t.created_at) }} • {{ timeTo(t.created_at) }}</p>
					</div>
					<Trigger :id="t.id" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Type'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Type'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast, successToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import mixinApp from '@/mixins/app'

export default {
	name: 'Type',
	mixins: [
		responsive,
		mixinApp
	],
	components: {
		Layout,
		Header,
		Placeholder,
		Trigger,
		Error,
		Results,
		'svg-icon': Icon
	},
	data() {
		return {
			loading: true,
			placeholder: 12,
			order: 'name',
			name: '',
			errored: false,
			types: []
		}
	},
	methods: {
        async findAll() {
			this.types = []
			this.loading = true
			try {
				const response = await Api.get('types/findAll')
				if (response.status === 200) {
					this.types = response.data
				}
			} catch (e) {
				console.log(e)
				errorToast(this.$t('alerts.load_error'))
			} finally {
				this.loading = false
			}
        },
		async filterByOrder() {
			this.types = []
			this.loading = true
			try {
				const response = await Api.post('types/filterByOrder', { order: this.order })
				if (response.status === 200) {
					this.types = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			this.types = []
			this.loading = true
			try {
				const empty = /^\s*$/
				if (!empty.test(this.name)) {
					const response = await Api.post('types/findByName', { name: this.name })
					if (response.status === 200) {
						this.types = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		modalCreate() {
			if (this.permissionEnabled('types', 'create')) {
				create(this, 'types', Modal, 'New')
			}
		},
		modalEdit(id) {
			if (this.permissionEnabled('types', 'edit')) {
				update(this, 'types', id, Modal, 'Edit')
			}
		}
	},
	mounted() {
		eventHub.$on('edit-modal-type', obj => {
			update(this, 'types', obj.id, Modal, 'Edit')
		})

		eventHub.$on('reload-types', () => {
			this.findAll()
		})

		eventHub.$on('delete-type', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('labels.warning'),
				message: '<span>' + this.$t('alerts.delete.confirmation') + '<span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('buttons.no'),
				confirmText: this.$t('buttons.yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`types/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('alerts.delete.success', [this.$tc('menu.types')]))
							this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('alerts.delete.error', [this.$tc('menu.types')]))
					}
				}
			})
		})

        this.findAll()
    },
	destroyed() {
		eventHub.$off('edit-modal-type')
		eventHub.$off('reload-type')
		eventHub.$off('delete-type')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
	}
}
</script>
