<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveType)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.types') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
                    <div class="columns is-multiline is-mobile mb-0">
						<div class="column is-12-mobile is-8-tablet">
							<InputWithValidation rules="required" :label="$tc('fields.name')" v-model="form.name" />
						</div>
						<div class="column is-12-mobile is-2-tablet">
							<div class="field preview">
								<label class="label">{{ $t('fields.icon') }}</label>
								<div class="icon" :class="{ 'is-valid': icon }">
									<img v-if="icon" loading="lazy" :src="icon" />
									<div v-else class="vue-swatches__diagonal"></div>
									<input class="input" type="file" accept="image/*" @change="updateIcon" /> 
								</div>
							</div>
						</div>
						<div class="column is-12-mobile is-2-tablet">
							<b-field :label="$tc('fields.color')" class="mb-2">
								<v-swatches v-model="form.color" :swatches="swatches" row-length="5" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
							</b-field>
						</div>
					</div>

					<InputWithValidation rules="required" class="mb-3" field="textarea" :label="$t('fields.description')" v-model="form.description" />
					
					<div class="field preview">
						<label class="label">{{ $tc('fields.image') }}</label>
						<div class="image" :class="{ 'is-valid': image }">
							<img v-if="image" loading="lazy" :src="image" />
							<input class="input" type="file" accept="image/*" @change="updateImage" />
						</div>
					</div>

					<small class="modal-updated" v-if="form.updated_at">{{ $t('labels.last_change') }} {{ format(form.updated_at) }}</small>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import Footer from '@/components/modals/Footer'
import VSwatches from 'vue-swatches'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver,
        VSwatches
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
            swatches: this.defaultColors(),
			permission: [],
			visible: false,
			form: {
				name: '',
				color: '',
				description: '',
				image: null,
				icon: null
			},
			image: null,
			icon: null,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`types/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.description = data.description
						this.form.color = data.color
						this.image = data.image
						this.icon = data.icon
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async update() {
			try {
				this.loading = true

				let formData = new FormData()

				if (this.form.image) {
					formData.append('image', this.form.image)
				}

				if (this.form.icon) {
					formData.append('icon', this.form.icon)
				}

				formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('color', this.form.color || '')
				formData.append('description', this.form.description || '')

				const response = await Api.post(`types/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/types')
					successToast(this.$t('alerts.update.success', [this.$tc('menu.types')]))
					eventHub.$emit('reload-types')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error', [this.$tc('menu.types')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()

				if (this.form.image) {
					formData.append('image', this.form.image)
				}

				if (this.form.icon) {
					formData.append('icon', this.form.icon)
				}

				formData.append('name', this.form.name)
				formData.append('color', this.form.color || '')
				formData.append('description', this.form.description || '')

				const response = await Api.post('types/store', formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/types')
					successToast(this.$t('alerts.create.success', [this.$tc('menu.types')]))
					eventHub.$emit('reload-types')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error', [this.$tc('menu.types')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveType() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async updateIcon (e) { 
			const file = e.target.files[0]
			this.icon = await this.readFile(file)
			this.form.icon = file
		},
		async updateImage (e) { 
			const file = e.target.files[0]
			this.image = await this.readFile(file)
			this.form.image = file
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
